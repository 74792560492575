// eslint-disable-next-line no-unused-vars
import { useCallback, useEffect, useRef, useState } from 'react';
import { GAIN_VALUE } from '../../variables/constant';
import { clearInterval, setInterval } from 'worker-timers';

/**
 * Checks whether the argument is a valid object i.e (key-value pair).
 * @param {any} o
 */
const isObject = (o: any) => o && !Array.isArray(o) && Object(o) === o;

/**
 * Checks whether media type(audio/video) constraints are valid.
 * @param {MediaStreamConstraints} mediaType
 */
const validateMediaTrackConstraints = (mediaType: string) => {
  const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
  const unSupportedMediaConstraints = Object.keys(mediaType).filter(
    // @ts-ignore
    (constraint) => !supportedMediaConstraints?.[constraint]
  );

  if (unSupportedMediaConstraints.length !== 0) {
    const toText = unSupportedMediaConstraints.join(',');
    console.error(`The following constraints ${toText} are not supported on this browser.`);
  }
};

const noop = () => {};

export enum Status {
  Idle = 'idle',
  AcquiringMedia = 'acquiring_media',
  Ready = 'ready',
  Recording = 'recording',
  Stopping = 'stopping',
  Stopped = 'stopped',
  Paused = 'paused',
  Failed = 'failed',
}

export interface MediaRecorderProps {
  blobOptions?: BlobPropertyBag;
  recordScreen?: boolean;
  onStart?: () => void;
  onStop?: (blob: Blob) => void;
  onDataAvailable?: (blob: Blob) => void;
  onError?: (e: Error) => void;
  mediaRecorderOptions?: object;
  mediaStreamConstraints: MediaStreamConstraints;
}

export interface MediaRecorderHookOptions {
  error: Error | null;
  status: Status;
  mediaBlob: Blob | null;
  isAudioMuted: boolean;
  stopRecording: () => void;
  getMediaStream: () => void;
  clearMediaStream: () => void;
  startRecording: (timeSlice?: number) => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  muteAudio: () => void;
  unMuteAudio: () => void;
  liveStream: MediaStream;
}

/**
 * @callback Callback
 * @param {Blob} blob
 *
 * @callback ErrorCallback
 * @param {Error} error
 *
 * @typedef MediaRecorderProps
 * @type {Object}
 * @property {BlobPropertyBag} [blobOptions]
 * @property {Boolean} [recordScreen]
 * @property {Function} [onStart]
 * @property {Callback} [onStop]
 * @property {Callback} [onDataAvailable]
 * @property {ErrorCallback} [onError]
 * @property {Object} [mediaRecorderOptions]
 * @property {MediaStreamConstraints} mediaStreamConstraints
 *
 * @typedef MediaRecorderHookOptions
 * @type {Object}
 * @property {?Error} error
 * @property {('idle'|'acquiring_media'|'ready'|'recording'|'stopping'|'stopped'|'failed')} status
 * @property {?Blob} mediaBlob
 * @property {Boolean} isAudioMuted
 * @property {Function} stopRecording,
 * @property {Function} getMediaStream,
 * @property {Function} clearMediaStream,
 * @property {Function} startRecording,
 * @property {Function} pauseRecording,
 * @property {Function} resumeRecording,
 * @property {Function} muteAudio
 * @property {Function} unMuteAudio
 * @property {?MediaStream} liveStream
 *
 * Creates a custom media recorder object using the MediaRecorder API.
 * @param {MediaRecorderProps}
 * @returns {MediaRecorderHookOptions}
 */
const useMediaRecorder = ({
  blobOptions,
  recordScreen = false,
  onStop = noop,
  onStart = noop,
  onError = noop,
  mediaRecorderOptions,
  onDataAvailable = noop,
  mediaStreamConstraints = {},
}: MediaRecorderProps) => {
  const mediaChunks = useRef([]);
  const mediaStream = useRef(<MediaStream | null>null);
  const mediaRecorder = useRef(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [mediaBlob, setMediaBlob] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [volumeOption1, setVolumeOption1] = useState(0);
  const [volumeOption2, setVolumeOption2] = useState(0);
  const source = useRef(<MediaStreamAudioSourceNode | null>null);
  const dest = useRef(<MediaStreamAudioDestinationNode | null>null);

  const getMediaStream = useCallback(async () => {
    if (error) {
      setError(null);
    }

    setStatus(Status.AcquiringMedia);
    try {
      let stream: any;

      if (recordScreen) {
        // @ts-ignore
        stream = await window.navigator.mediaDevices.getDisplayMedia(mediaStreamConstraints);
      } else {
        const audioStream = await window.navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
        const ctx = new AudioContext();
        source.current = ctx.createMediaStreamSource(audioStream);
        dest.current = ctx.createMediaStreamDestination();
        const gainNode = ctx.createGain();
        // Adding gain value as we were facing low volume issues with iPads(https://technine.atlassian.net/browse/ABL-880)
        gainNode.gain.value = GAIN_VALUE;
        source.current.connect(gainNode);
        gainNode.connect(dest.current);
        stream = audioStream;
      }

      if (recordScreen && mediaStreamConstraints.audio) {
        const audioStream = await window.navigator.mediaDevices.getUserMedia({
          audio: mediaStreamConstraints.audio,
        });

        audioStream.getAudioTracks().forEach((audioTrack) => stream.addTrack(audioTrack));
      }

      mediaStream.current = stream;
      setStatus(Status.Ready);
    } catch (err) {
      setError(err);
      setStatus(Status.Failed);
    }
  }, [error, mediaStreamConstraints, recordScreen]);

  const clearMediaStream = () => {
    if (mediaStream.current) {
      source.current?.mediaStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      dest.current?.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      mediaStream.current?.getTracks().forEach((track: MediaStreamTrack) => track.stop());

      mediaStream.current = null;
      dest.current = null;
      source.current = null;
    }
  };

  const startRecording = async (timeSlice: any) => {
    if (error) {
      setError(null);
    }

    if (!mediaStream.current) {
      await getMediaStream();
    }

    mediaChunks.current = [];

    if (mediaStream.current) {
      // attach a visualizer
      const audioCtx = new AudioContext();
      const source = audioCtx.createMediaStreamSource(mediaStream.current);
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      source.connect(analyser);

      let previousVolume = 0;
      const smoothingFactor = 0.025;

      function draw() {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);
        const currentVolume = Math.max(...dataArray);
        setVolumeOption1(currentVolume);
        const smoothedVolume = previousVolume + (currentVolume - previousVolume) * smoothingFactor;
        setVolumeOption2(smoothedVolume);
        previousVolume = smoothedVolume;
      }

      draw();

      // @ts-ignore
      mediaRecorder.current = new MediaRecorder(mediaStream.current, mediaRecorderOptions);
      // @ts-ignore
      mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
      // @ts-ignore
      mediaRecorder.current.addEventListener('stop', handleStop);
      // @ts-ignore
      mediaRecorder.current.addEventListener('error', handleError);
      // @ts-ignore
      mediaRecorder.current.start(timeSlice);
      setStatus(Status.Recording);
      onStart();
    }
  };

  const handleDataAvailable = (e: { data: Blob }) => {
    if (e.data.size) {
      // @ts-ignore
      mediaChunks.current.push(e.data);
    }
    onDataAvailable(e.data);
  };

  const handleStop = () => {
    const [sampleChunk] = mediaChunks.current;
    const blobPropertyBag = {
      // @ts-ignore
      type: sampleChunk.type,
      ...blobOptions,
    };
    const blob = new Blob(mediaChunks.current, blobPropertyBag);
    // @ts-ignore
    setMediaBlob(blob);
    setStatus(Status.Stopped);
    onStop(blob);
  };

  const handleError = (e: any) => {
    setError(e.error);
    setStatus(Status.Idle);
    onError(e.error);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);

    if (mediaStream.current) {
      // @ts-ignore
      mediaStream.current.getAudioTracks().forEach((audioTrack) => {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        audioTrack.enabled = !mute;
      });
    }
  };

  const pauseRecording = () => {
    // @ts-ignore
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus(Status.Paused);
      // @ts-ignore
      mediaRecorder.current.pause();
    }
  };

  const resumeRecording = () => {
    // @ts-ignore
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      setStatus(Status.Recording);
      // @ts-ignore
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      setStatus(Status.Stopping);
      setElapsedTime(0);
      // @ts-ignore
      mediaRecorder.current.stop();
      // not sure whether to place clean up in useEffect?
      // If placed in useEffect the handler functions become dependencies of useEffect
      // @ts-ignore
      mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable);
      // @ts-ignore
      mediaRecorder.current.removeEventListener('stop', handleStop);
      // @ts-ignore
      mediaRecorder.current.removeEventListener('error', handleError);
      mediaRecorder.current = null;
      clearMediaStream();
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (!window.MediaRecorder) {
      throw new ReferenceError(
        'MediaRecorder is not supported in this browser. Please ensure that you are running the latest version of chrome/firefox/edge.'
      );
    }

    // @ts-ignore
    if (recordScreen && !window.navigator.mediaDevices.getDisplayMedia) {
      throw new ReferenceError('This browser does not support screen capturing.');
    }

    if (isObject(mediaStreamConstraints.video)) {
      // @ts-ignore
      validateMediaTrackConstraints(mediaStreamConstraints.video);
    }

    if (isObject(mediaStreamConstraints.audio)) {
      // @ts-ignore
      validateMediaTrackConstraints(mediaStreamConstraints.audio);
    }

    // @ts-ignore
    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      // @ts-ignore
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error('The specified MIME type supplied to MediaRecorder is not supported by this browser.');
      }
    }
  }, [mediaStreamConstraints, mediaRecorderOptions, recordScreen]);

  useEffect(() => {
    let interval: any;
    if (status === 'recording') {
      interval = setInterval(() => setElapsedTime((prev) => prev + 1), 1000);
    }
    return () => clearInterval(interval);
  }, [status]);

  return {
    elapsedTime,
    error,
    status,
    mediaBlob,
    isAudioMuted,
    stopRecording,
    getMediaStream,
    startRecording,
    pauseRecording,
    resumeRecording,
    clearMediaStream,
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    get liveStream() {
      if (mediaStream.current) {
        // @ts-ignore
        return new MediaStream(mediaStream.current.getVideoTracks());
      }
      return null;
    },
    volumeOption1,
    volumeOption2,
  };
};

export default useMediaRecorder;
